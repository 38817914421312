import { LOG_FAILURE, LOG_SUCCESS, LOG_WOD } from 'components/puzzles/PuzzleDetails-helpers';
import { IWordDefinition, IWordsDefinitions, WordDefinitionCallback } from 'components/puzzles/PuzzleDetails.types';

const hasCachedWordDefinition = (wordAsKey: string): boolean => localStorage.getItem(wordAsKey) !== null;

const getCachedWordDefinition = (word: string): IWordDefinition =>{
  const stringData = localStorage.getItem(word) || '';
  try {
    const jsonData = JSON.parse(stringData);
    const def = <IWordsDefinitions>(<unknown>jsonData);
    return <IWordDefinition>def[0];
  } catch (error) {
    return {
      word: '',
      license: {name: '', url: ''},
      meanings: [],
      phonetic: '',
      phonetics: [],
      sourceUrls: [],
      isError: false,
    };
  }
};

const getWordDefinition = (word: string, callback: WordDefinitionCallback) => {
  if (hasCachedWordDefinition(word)) {
    console.log(`Definition for %c${word}%c exists in local storage`, LOG_WOD, LOG_SUCCESS);
    return callback(getCachedWordDefinition(word));
  }
  console.log(`Definition for %c${word}%c is not in local storage`, LOG_WOD, LOG_FAILURE);
  return fetchWordDefinition(word, callback);
};

const fetchWordDefinition = (word: string, callback: WordDefinitionCallback) => {
  const openDictionaryApiUrl = 'https://api.dictionaryapi.dev/api/v2/entries/en/';
  if (word) {
    fetch(`${openDictionaryApiUrl}${word}`, { method: 'GET' })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } 
        throw Error(`Definition for word %c${word}%c cannot be found`);
      })
      .then((actualData) => {
        const wordDefinitions = <IWordsDefinitions>actualData;

        // definition?.phonetics
        const wordDef = <IWordDefinition>wordDefinitions[0];
        localStorage.setItem(wordDef.word.toUpperCase(), JSON.stringify(actualData));
        console.log(wordDefinitions[0].word);
        callback(wordDef);
      })
      .catch((err) => {
        console.log(err.message, LOG_WOD, LOG_FAILURE);
        callback({
          word,
          license: {name: '', url: ''},
          meanings: [],
          phonetic: '',
          phonetics: [],
          sourceUrls: [],
          isError: true,
        });
      });
  }
};

export { getWordDefinition };
