import { getWordsStartingWithLetter } from 'components/puzzles/PuzzleDetails-helpers';
import { IPuzzleDictionary } from 'components/puzzles/PuzzleDetails.types';
import React, { useMemo } from 'react';

import PuzzleDictionaryWord from '../dictionary/PuzzleDictionaryWord';

import styles from './AlphabetScrollList.module.scss';

type IAlphabetScrollWordsByLetterProps = {
  letter: string;
  dictionary: IPuzzleDictionary;
  isActiveLetter: boolean;
};

const AlphabetScrollWordsByLetter: React.FunctionComponent<IAlphabetScrollWordsByLetterProps> = ({
  letter,
  dictionary,
  isActiveLetter,
}: IAlphabetScrollWordsByLetterProps) => {

  const renderLetterTitleRow = (startingLetter: string): JSX.Element => {
    const rowKey = `l_${startingLetter}`;
    const headerId = `letter_header_row_${startingLetter}`;
    return (
      <dt id={headerId} key={rowKey} className={styles.AlphabetListTitleRow}>
        {startingLetter}
      </dt>
    );
  };

  const wordsStartingWithLetter = useMemo(() => {
    return getWordsStartingWithLetter(letter, dictionary);
  }, [letter, dictionary]); 
  
  const displayedWords: ReadonlyArray<JSX.Element> = useMemo(() => {
    const puzzleWords = wordsStartingWithLetter.map((wd: string, index: number) => {
      const wordKey = `words_by_${letter}_${wd}`;
      const wordId = index === 0 ? `words_by_${letter}` : wordKey;
      return <PuzzleDictionaryWord wordId={wordId} key={wordKey} word={wd} />;
    });
    // Add letter header row
    puzzleWords.unshift(renderLetterTitleRow(letter));
    return puzzleWords;
  }, [wordsStartingWithLetter]);

  const renderWordsByLetter = () => {
    const maxWords = isActiveLetter ? displayedWords.length - 1 : 20;
    if (maxWords > 20) {
      console.log(`Rendering ${maxWords} for letter ${letter} out of (${displayedWords.length})`);
    }
    return <div key={`wd_start_by_${letter}`}>{displayedWords.slice(0, maxWords)}</div>;
  };

  return renderWordsByLetter();
};

export default AlphabetScrollWordsByLetter;
