export const FirebaseConfig = {
    apiKey: 'AIzaSyC_pgXCIcrZ_lxUEfCO4mNbiy2VENb9h9A',
    authDomain: 'cookbook-prototype.firebaseapp.com',
    databaseURL: 'https://cookbook-prototype.firebaseio.com',
    projectId: 'cookbook-prototype',
    storageBucket: 'cookbook-prototype.appspot.com',
    messagingSenderId: '289116889032',
    appId: '1:289116889032:web:6f39829010c6513266aafd',
    measurementId: 'G-BB1SLJPYEM',
};
