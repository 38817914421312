import React, { useEffect, useMemo, useState } from 'react';

import { IPuzzleDictionary } from '../../PuzzleDetails.types';
import WordSelector from '../dropdown/WordSelector';

import { Search } from 'react-bootstrap-icons';

import styles from './PuzzleDictionary.module.scss';
import AlphabetScrollList from '../alphabetscroll/AlphabetScrollList';

type IPuzzleDictionaryProps = {
  isLoaded: boolean;
  dictionary: IPuzzleDictionary;
};

const PuzzleDictionary: React.FunctionComponent<IPuzzleDictionaryProps> = ({
  isLoaded,
  dictionary,
}: IPuzzleDictionaryProps) => {
  /** true when dictionary has been created and configured */
  const [isReady, setReady] = useState<boolean>(false);
  const [wordSelected, setWordSelected] = useState<string>('');

  const selectableWords = useMemo(() => {
    return dictionary.words.map((word: string) => ({ value: word, label: word }));
  }, [isLoaded]);

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  useEffect(() => {
    if (isLoaded && dictionary.words.length > 0) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [isLoaded, dictionary]);

  const renderDictionaryContainer = () => {
    if (isReady) {
      return (
        <div className={styles.PuzzleDictionary}>
          <div className={styles.WordSearchRow}>
            <Search className={styles.WordSearchRowIcon} size={24} />
            <WordSelector
              words={selectableWords}
              autoFocus={true}
              onWordSelected={setWordSelected}
              placeholder={<span className={styles.PlaceholderMessage}>Search</span>}
            />
          </div>
          <div className={styles.PuzzleDictionaryScrollListWrapper}>
          <AlphabetScrollList dictionary={dictionary} searchWord={wordSelected} />
          </div>
        </div>
      );
    }
    return <>DICTIONARY UNAVAILABLE</>;
  };

  return <>{renderDictionaryContainer()}</>;
};

export default PuzzleDictionary;
