import React, { useEffect, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Robot, Book, BookHalf, Diagram3 } from 'react-bootstrap-icons';

import { allAvailableWords } from './PuzzleWords';
import { IPuzzleDictionary, IAnalyzerData } from './PuzzleDetails.types';
import { createDictionary } from './PuzzleDetails-helpers';
import PuzzleDetailsStats from './components/stats/PuzzleDetailsStats';
import PuzzleDetailsSolver from './components/solver/PuzzleDetailsSolver';

import useDeviceDetect from './hooks/useDeviceDetect';

import styles from './PuzzleDetailsScene.module.scss';
import PuzzleDictionary from './components/dictionary/PuzzleDictionary';

const NON_BREAKING_SPACE: JSX.Element = <>&nbsp;</>;

const DISPLAY_ITEM_PUZZLE = '0';
const DISPLAY_ITEM_STATS = '1';
const DISPLAY_ITEM_DICTIONARY = '2';

const initialDictionary: IPuzzleDictionary = {
  words: [],
  letters: [],
};

const PuzzleDetailsScene: React.FunctionComponent = () => {
  const bodyContainerRef = useRef(null);
  const [dictionaryLoaded, setDictionaryLoaded] = useState<boolean>(false);
  const [dictionaryWords, setDictionaryWords] = useState<IPuzzleDictionary>(initialDictionary);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [analyzerData, setAnalyzerData] = useState<IAnalyzerData>({});
  // const [lastSolution, setLastSolution] = useState<IPuzzleSolution>();
  const [activeDisplayItem, setActiveDisplayItem] = useState<string>('0');

  const { isMobile, isDeviceWidthXL } = useDeviceDetect();

  useEffect(() => {
    console.log('[PuzzleDetailsScene] initialized');
    const dict = createDictionary(allAvailableWords);
    setDictionaryWords(dict);
  }, [allAvailableWords]);

  useEffect(() => {
    if (dictionaryWords.words.length === allAvailableWords.length) {
      setDictionaryLoaded(true);
    }
  }, [allAvailableWords, dictionaryWords]);

  const analyzerHandler = (analyzerData: IAnalyzerData) => {
    setAnalyzerData(analyzerData);
    if (analyzerData.shouldAnalyze) {
      setActiveDisplayItem('1');
    }
  };

  const renderPuzzleSolverHeading = () => (
    <span className={styles.HeaderLabel}>
      Solver {NON_BREAKING_SPACE}
      <Robot size={18} />
    </span>
  );

  const renderDictionaryHeading = () => (
    <span className={styles.HeaderLabel}>
      Dictionary {NON_BREAKING_SPACE}
      {activeDisplayItem === DISPLAY_ITEM_DICTIONARY ? <BookHalf size={18} /> : <Book size={18} />}
    </span>
  );

  const renderStatsHeading = () => (
    <span className={styles.HeaderLabel}>
      Stats {NON_BREAKING_SPACE}
      <Diagram3 size={18} />
    </span>
  );

  const renderPuzzleSolver = () => (
    <PuzzleDetailsSolver
      dictionary={dictionaryWords}
      isLoaded={dictionaryLoaded}
      analyzeSolutionHandler={analyzerHandler}
    />
  );

  const renderPuzzleStats = () => (
    <PuzzleDetailsStats isLoaded={dictionaryLoaded} dictionary={dictionaryWords} analyzeSolution={analyzerData} />
  );

  const renderDictionary = () => <PuzzleDictionary isLoaded={dictionaryLoaded} dictionary={dictionaryWords} />;

  const renderAccordionDisplayContainer = (): JSX.Element => {
    return (
      <Accordion
        // flush={isDeviceWidthXXS()}
        flush
        className="h-100"
        activeKey={activeDisplayItem}
        onSelect={(eventKey) => setActiveDisplayItem(eventKey as string)}
      >
        <Accordion.Item eventKey={DISPLAY_ITEM_PUZZLE}>
          <Accordion.Header as={'h6'}>{renderPuzzleSolverHeading()}</Accordion.Header>
          <Accordion.Body ref={bodyContainerRef} className={styles.RelativePosition}>
            {renderPuzzleSolver()}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={DISPLAY_ITEM_STATS}>
          <Accordion.Header as={'h6'}>{renderStatsHeading()}</Accordion.Header>
          <Accordion.Body>{renderPuzzleStats()}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey={DISPLAY_ITEM_DICTIONARY}>
          <Accordion.Header as={'h6'}>{renderDictionaryHeading()}</Accordion.Header>
          <Accordion.Body>{renderDictionary()}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const renderTabDisplayContainer = () => {
    return (
      <Tabs
        activeKey={activeDisplayItem}
        onSelect={(eventKey) => setActiveDisplayItem(eventKey as string)}
        className="mb-3"
        fill
      >
        <Tab eventKey={DISPLAY_ITEM_PUZZLE} title={renderPuzzleSolverHeading()} className={styles.RelativePosition}>
          <div ref={bodyContainerRef}>{renderPuzzleSolver()}</div>
        </Tab>
        <Tab eventKey={DISPLAY_ITEM_STATS} title={renderStatsHeading()}>
          {renderPuzzleStats()}
        </Tab>
        <Tab eventKey={DISPLAY_ITEM_DICTIONARY} title={renderDictionaryHeading()}>
          {renderDictionary()}
        </Tab>
      </Tabs>
    );
  };

  const renderPlatformDisplayContainer = () => {
    if (isMobile && isDeviceWidthXL()) {
      return renderAccordionDisplayContainer();
    } else {
      return renderTabDisplayContainer();
    }
  };

  return <div className={styles.PuzzleScene}>{renderPlatformDisplayContainer()}</div>;
};

export default PuzzleDetailsScene;
